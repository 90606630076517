import { useState, useEffect } from 'react';

import { dehydrate, QueryClient, useQuery } from 'react-query';
import { queryClientConfig } from '@core/configs/queryClientConfig';

import Parser from '@core/components/contentparser/Parser';
import Spinner from '@core/components/spinner/Spinner';
import { GetHomeContents } from 'services/core';

import { setSsrTokens } from 'utils/authentication';

import { NextSeo } from 'next-seo';
import { getHomeSeoProps } from 'router/seoprops';
import Head from 'next/head';
import OpenGraphImage from 'assets/images/pharmunihomepage.jpg';


const Home = () => {
	const { data, isLoading } = useQuery(['home-page'], GetHomeContents);
	const [start, setStart] = useState('undefined');
	useEffect(() => {
		setStart(typeof window);
	}, [start]);

	const seoProps = getHomeSeoProps();
	return (
		<>
			<NextSeo {...seoProps} />
			<Head>
				<meta property="og:image:url" content="https://pharmuni.com/wp-content/uploads/2024/06/pharmuniOG.png" />
				<meta property="og:image:secure_url" content="https://pharmuni.com/wp-content/uploads/2024/06/pharmuniOG.png" />
				<meta name="twitter:image" content="https://pharmuni.com/wp-content/uploads/2024/06/pharmuniOG.png" />
				<meta name="twitter:card" content="summary_large_image" />
			</Head>
			{data && start !== "undefined" && !isLoading ? (
				<Parser data={data.data} origin={'home'} />
			) : (
				<div className="w-100 my-5 py-5 d-flex align-items-center justify-content-center">
					<Spinner />
				</div>
			)}
		</>
	);
};

export async function getServerSideProps(context) {
	setSsrTokens(context);
	const queryClient = new QueryClient(queryClientConfig);
	const { req } = context;
	const userIp = req.headers['x-forwarded-for'];
	const response = await GetHomeContents(userIp);
	await queryClient.prefetchQuery(['home-page'], () => ({ data: response.data }));

	return {
		props: {
			dehydratedState: dehydrate(queryClient)
		}
	};
}

export default Home;
